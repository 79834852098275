var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("severity")]),_vm._l((_vm.riskList),function({ text, value }){return _c('th',{key:value},[_vm._v(_vm._s(text))])})],2)]),_c('tbody',[_c('div',{staticClass:"drag-and-drop-zone__container"},_vm._l((_vm.dragAndDropList),function(item){return _c('div',{key:item.id,staticClass:"drop-zone",on:{"drop":function($event){return _vm.onDrop($event, item.id)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[(item.dragIndex >= 0)?_c('div',{staticClass:"drag-el",attrs:{"draggable":""},on:{"dragstart":function($event){return _vm.startDrag($event, item.dragIndex)}}},[_c('img',{attrs:{"src":require("../../../../../assets/equal-icon.svg")}})]):_vm._e()])}),0),_c('div',{staticClass:"highlight-columns__container"},[_c('div',{staticClass:"severity-highlight-columns__column"},_vm._l((_vm.dragAndDropList),function(item,rowInd){return _c('div',{key:item.id,staticClass:"severity-highlight-columns__row",class:{
            inRange: _vm.isRowInRange(rowInd),
            firstInRange: rowInd === _vm.severityRange[0],
            lastInRange: rowInd === _vm.severityRange[1],
          }})}),0),_vm._l((_vm.riskList),function({ value }){return _c('div',{key:value,staticClass:"highlight-columns__column",class:{ selected: value === _vm.selectedColumn }},_vm._l((_vm.dragAndDropList),function(item,rowInd){return _c('div',{key:item.id,staticClass:"highlight-columns__row",class:{
            inRange: _vm.isRowInRange(rowInd),
            firstInRange: rowInd === _vm.severityRange[0],
            lastInRange: rowInd === _vm.severityRange[1],
          }})}),0)})],2),_vm._l((_vm.tableData),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"severity-cell",class:{
          firstInRange: index === _vm.severityRange[0],
          lastInRange: index === _vm.severityRange[1],
        }},[_c('p',[_vm._v(_vm._s(item.Severity))])]),_vm._l((_vm.riskList),function({ value }){return _c('td',{key:value},[_c('p',{class:{ selected: value === _vm.selectedColumn && _vm.isRowInRange(index) }},[_vm._v(" "+_vm._s(item[value])+"% ")])])})],2)}),_c('tr',[(_vm.weightedNpv)?[_vm._m(0),_vm._l((_vm.riskList),function({ value }){return _c('td',{key:value,staticClass:"weighted-cell"},[_c('p',[_vm._v(" "+_vm._s(_vm.weightedNpv[value] ? `${_vm._formatCurrencyV2( _vm.weightedNpv[value].WeightedNPV, _vm.rangeCurrencyAndUnit.currency, _vm.weightedNpv[value].Unit )}` : '')+" ")])])})]:_c('div',{staticClass:"col-loading"},[_c('Skeleton')],1)],2)],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"weighted-cell"},[_c('p',[_vm._v("weighted npv")])])
}]

export { render, staticRenderFns }