
import { Component, Vue } from 'vue-property-decorator';
import LossSeverityRiskWidget from './widgets/LossSeverityRiskWidget.vue';
import LossSeverityChart from './chart-components/LossSeverityChart.vue';
import PeersAreaChartLegend from '../PeerBenchmarkingArea/PeersAreaChartLegend/PeersAreaChartLegend.vue';
import PeersAreaChart from '../PeerBenchmarkingArea/PeersAreaChart/PeersAreaChart.vue';
import LossSeverityTable from './tables/LossSeverityTable.vue';
import {
  LossSeverityModule,
  LossSeverityRiskGroup,
} from '@/store/modules/company-tabs/loss-severity';
import BreachRatioWidget from './widgets/BreachRatioWidget.vue';
import { BreachRatioModule } from '@/store/modules/company-tabs/breach-ratio';

@Component({
  components: {
    LossSeverityRiskWidget,
    LossSeverityChart,
    PeersAreaChartLegend,
    PeersAreaChart,
    LossSeverityTable,
    BreachRatioWidget,
  },
})
export default class AdvisoryOverviewPage extends Vue {
  public currentRisk: LossSeverityRiskGroup =
    LossSeverityModule.currentRiskGroup || LossSeverityRiskGroup.Low;
  public severityRange: [number, number] = [0, LossSeverityModule.plotsWithNPVLength - 1];

  get breachRatioCurrent() {
    return BreachRatioModule.current;
  }

  setCurrentRisk(risk: LossSeverityRiskGroup) {
    this.currentRisk = risk;
  }

  setSeverityRange(range: [number, number]) {
    this.severityRange.splice(0, this.severityRange.length, ...range);
  }

  get companyTitle() {
    return this.$store.getters.companyFullName as string;
  }

  get companyPeerSvg() {
    return this.$store.getters.companyPeerSvg as 'ACME' | 'AZ' | null;
  }

  get lossType() {
    return LossSeverityModule.lossTypeStored;
  }
}
