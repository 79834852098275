
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Tachometer extends Vue {
  @Prop() height!: number;
  @Prop() width!: number;
  @Prop() length!: number;
  @Prop() from!: number;
  @Prop() to!: number;
  @Prop() value!: number;
  @Prop() thickness!: number;
  @Prop() secondColor!: string;
  @Prop() bgColor!: string;
  @Prop() colorRules!: string[];
  @Prop() valueRanges!: number[];
  @Prop() speedometer!: boolean;
  @Prop() centerHeight!: number;
  @Prop() centerWidth!: number;
  @Prop() shadowSize!: number;
  @Prop() arrowWidth!: number;

  get angl() {
    return this.countAngl(this.value);
  }

  countAngl(value: number) {
    let ang = 0;
    ang = (180 / (this.to - this.from)) * (value - this.from);
    return ang;
  }

  get valuedColor() {
    // easy spread
    let i = Math.floor(((this.value - this.from) * this.colorRules.length) / (this.to - this.from));

    //custom spread
    if (this.valueRanges) {
      i = -1;
      for (let j = 0; j < this.valueRanges.length; j++) {
        if (this.value >= this.valueRanges[j]) {
          i = j;
        }
      }
      i = i + 1;
    }

    if (i >= this.colorRules.length) {
      i = this.colorRules.length - 1;
    }

    // speedometer
    if (this.speedometer && this.valueRanges) {
      let colorSet = `${this.colorRules[0]} ${this.countAngl(this.valueRanges[0])}deg, `;
      for (let j = 1; j < this.valueRanges.length; j++) {
        colorSet =
          colorSet +
          `${this.colorRules[j]} ${this.countAngl(this.valueRanges[j - 1])}deg ${this.countAngl(
            this.valueRanges[j]
          )}deg,`;
      }
      colorSet = colorSet.slice(0, colorSet.length - 1);
      return colorSet;
    }

    return `${this.colorRules[i]} ${this.angl}deg`;
  }
}
