
import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
import { Chart } from '@/libs/chart-lib';
import { createChart } from '../chart-configs/loss-severity-chart-config';
import RadioGroup from '@/components/RadioGroup.vue';
import {
  LossSeverityModule,
  LossSeverityPlotItem,
  LossSeverityRiskGroup,
  LossTypes,
} from '@/store/modules/company-tabs/loss-severity';
import { formatCurrencyV2, LossSeverityBgColorScheme, LossSeverityColorScheme } from '../utils';
import { Tooltip, tooltipObject } from '@/libs/chart-lib/classes/tooltip/Tooltip';

@Component({ components: { RadioGroup } })
export default class LossSeverityChart extends Vue {
  @Prop() severityRange!: [number, number];
  @Prop() selectedColumn!: LossSeverityRiskGroup;

  private chart?: Chart;
  private updateGradientSeries?: (selectedId: string, ind: number) => void;
  private selectedRisk?: LossSeverityRiskGroup;
  private prevTTparams?: {
    risk: LossSeverityRiskGroup;
    ind: number;
  };
  public lossType: LossTypes = LossTypes.economic;

  get lossTypeOptions(): { label: string; value: LossTypes }[] {
    return [
      {
        label: 'Economic Loss',
        value: LossTypes.economic,
      },
      {
        label: 'Insurable Loss',
        value: LossTypes.insurable,
      },
    ];
  }

  created() {
    this.lossType = LossSeverityModule.lossTypeStored;
  }

  reduceLossType(item: { label: string; value: LossTypes }) {
    return item.value;
  }

  @Watch('lossType')
  onLossTypeChange() {
    LossSeverityModule.setLossType(this.lossType);
  }

  mounted() {
    const { chart, updateGradientSeries, drag } = createChart({
      container: this.$refs.chartContainer as HTMLElement,
      plots: this.chartPlots,
      currentId: this.currentRisk,
      rangeDataX: this.rangeData.x,
      uncoveredRangeDataX: this.uncoveredRangeData.x,
      retainedRangeDataX: this.retainedRangeData.x,
      rangeLabels: this.rangeData.labels,
      midLabel: this.getMidLabel(),
      enabledDragData: this.enabledSeriesRangeData,
      xAxisTextDecorator: (str) =>
        formatCurrencyV2(
          Math.abs(Number(str)),
          LossSeverityModule.currency,
          LossSeverityModule.unit
        ),
    });
    this.chart = chart;
    this.updateGradientSeries = updateGradientSeries;
    this.showCurrentSeries();
    this.onRiskSelect(this.selectedColumn);
    chart.tooltipsDataIndexUpdated.add((payload) => {
      const tooltip = payload.tooltip as Tooltip;
      const tt = payload.tt as tooltipObject;
      if (tooltip.id === `${this.currentRisk}_point_tt-line`) {
        if (this.updateGradientSeries && this.selectedRisk) {
          if (
            this.prevTTparams &&
            this.prevTTparams.risk === this.selectedRisk &&
            this.prevTTparams.ind === tt.ind
          ) {
            return;
          }
          this.prevTTparams = {
            risk: this.selectedRisk,
            ind: tt.ind,
          };
          this.updateGradientSeries(this.prevTTparams.risk, this.prevTTparams.ind);
        }
      }
    });
    drag.onIndexUpdated.add((prevInd, curInd) => {
      const newRange = [...this.severityRange];
      newRange[prevInd] = curInd;
      this.onSeverityRangeChange(newRange as [number, number]);
    });
  }

  activated() {
    window.dispatchEvent(new Event('resize'));
  }

  toggleSeries(id: LossSeverityRiskGroup, show: boolean) {
    if (!this.chart) return;

    const seriesCurve = this.chart.data.findSeriesById(`${id}_curve`);

    if (seriesCurve) {
      seriesCurve.setPlotsIds(...(show ? [`${id}_line_dashed`] : []));
      this.chart.seriesReDraw_Static(seriesCurve);
    }

    const seriesPointsY = this.chart.data.findSeriesById(`${id}_points_y`);
    if (seriesPointsY) {
      seriesPointsY.setPlotsIds(...(show ? [`${id}_point`] : []));
      this.chart.seriesReDraw_Static(seriesPointsY);
    }
  }

  showCurrentSeries() {
    if (!this.chart) return;

    const seriesCurve = this.chart.data.findSeriesById(`${this.currentRisk}_curve`);

    if (seriesCurve) {
      seriesCurve.setPlotsIds(`${this.currentRisk}_line`);
      this.chart.seriesReDraw_Static(seriesCurve);
    }

    const seriesPointsY = this.chart.data.findSeriesById(`${this.currentRisk}_points_y`);

    if (seriesPointsY) {
      seriesPointsY.setPlotsIds(`${this.currentRisk}_point`);
      this.chart.seriesReDraw_Static(seriesPointsY);
    }
  }

  @Emit()
  onRiskChange() {
    this.updateMidLabel();
    return this.selectedRisk;
  }

  onRiskSelect(value: LossSeverityRiskGroup) {
    this.selectedRisk = value;
    this.onRiskChange();
    Object.values(LossSeverityRiskGroup).forEach((risk) => {
      const isCurrent = risk === this.currentRisk;
      if (!isCurrent) {
        const isSelected = risk === this.selectedRisk;
        this.toggleSeries(risk, isSelected);
      }
    });
    if (this.chart) {
      this.chart.tooltipsDraw(undefined, true);
    }
  }

  preparePlotData(plot: LossSeverityPlotItem[], withLabels?: boolean) {
    return [...plot].reverse().reduce(
      (acc, cur) => {
        acc.x.push(-cur.LossSeverityAxisX);
        acc.y.push(cur.FrequencyAxisY);
        if (withLabels) {
          if (!acc.label_y) acc.label_y = [];
          acc.label_y.push(String(cur.FrequencyAxisY) + '%');
          if (!acc.label_x) acc.label_x = [];
          acc.label_x.push(
            formatCurrencyV2(
              cur.LossSeverityAxisX,
              cur.LossSeverityAxisXCurrency,
              cur.LossSeverityAxisXUnit
            )
          );
          if (!acc.label_tt) acc.label_tt = [];
          acc.label_tt.push(
            formatCurrencyV2(
              cur.NetPresentValue || 0,
              cur.LossSeverityAxisXCurrency,
              cur.NetPresentValueLegend
            )
          );
        }
        return acc;
      },
      { x: [], y: [] } as {
        x: number[];
        y: number[];
        label_y?: string[];
        label_x?: string[];
        label_tt?: string[];
      }
    );
  }

  beforeDestroy() {
    delete this.chart;
  }

  get chartPlots() {
    return Object.values(LossSeverityRiskGroup)
      .map((risk) => ({
        curve: this.preparePlotData(LossSeverityModule.plots[risk] || []),
        points: this.preparePlotData(LossSeverityModule.plotsWithNPV[risk] || [], true),
        id: risk,
        color: LossSeverityColorScheme[risk],
        bgColor: LossSeverityBgColorScheme[risk],
      }))
      .reverse();
  }

  get rangeData() {
    const arr = LossSeverityModule.plotsWithNPV[this.currentRisk] || [];
    const indList = [...this.severityRange].sort();
    return {
      x: indList.map((val) => -arr[val].LossSeverityAxisX),
      labels: indList.map((val) =>
        formatCurrencyV2(
          arr[val].LossSeverityAxisX,
          arr[val].LossSeverityAxisXCurrency,
          arr[val].LossSeverityAxisXUnit
        )
      ),
    };
  }

  get transferableValue() {
    const value = this.rangeData.x[0] - this.rangeData.x[1];
    return (
      (value && formatCurrencyV2(value, LossSeverityModule.currency, LossSeverityModule.unit)) || ''
    );
  }

  get uncoveredRangeData() {
    const arr = LossSeverityModule.plotsWithNPV[this.currentRisk] || [];
    return {
      x: [-arr[arr.length - 1].LossSeverityAxisX, this.rangeData.x[this.rangeData.x.length - 1]],
    };
  }

  get retainedRangeData() {
    const arr = LossSeverityModule.plotsWithNPV[this.currentRisk] || [];
    return {
      x: [-arr[0].LossSeverityAxisX, this.rangeData.x[0]],
    };
  }

  get retainedValue() {
    const value = this.retainedRangeData.x[0] - this.retainedRangeData.x[1];
    return (
      (value && formatCurrencyV2(value, LossSeverityModule.currency, LossSeverityModule.unit)) || ''
    );
  }

  get enabledSeriesRangeData() {
    return (LossSeverityModule.plotsWithNPV[this.currentRisk] || []).map((item) => {
      return {
        x: -item.LossSeverityAxisX,
        y: 0,
        label: formatCurrencyV2(
          item.LossSeverityAxisX,
          item.LossSeverityAxisXCurrency,
          item.LossSeverityAxisXUnit
        ),
      };
    });
  }

  @Emit()
  onSeverityRangeChange(newRange: [number, number]) {
    return newRange;
  }

  @Watch('severityRange')
  onSeverityRangeChanged() {
    if (!this.chart) return;
    const seriesRange = this.chart.data.findSeriesById('range_series');
    if (seriesRange) {
      seriesRange.replaceSeriesData(
        [this.rangeData.x, this.rangeData.x.map(() => 0)],
        true,
        this.rangeData.labels
      );
    }
    const uncoveredSeriesRange = this.chart.data.findSeriesById('uncovered_range_series');
    if (uncoveredSeriesRange) {
      uncoveredSeriesRange.replaceSeriesData(
        [this.uncoveredRangeData.x, this.uncoveredRangeData.x.map(() => 0)],
        true
      );
    }
    const retainedSeriesRange = this.chart.data.findSeriesById('retained_range_series');
    if (retainedSeriesRange) {
      retainedSeriesRange.replaceSeriesData(
        [this.retainedRangeData.x, this.retainedRangeData.x.map(() => 0)],
        true
      );
    }
    this.updateMidLabel();
  }

  get currentRisk() {
    return LossSeverityModule.currentRiskGroup || LossSeverityRiskGroup.Low;
  }

  get riskList() {
    return Object.values(LossSeverityRiskGroup).map((value) => ({
      value,
      text: value === LossSeverityModule.currentRiskGroup ? value + ' (CURRENT)' : value,
    }));
  }

  get severityList() {
    const sevItem =
      LossSeverityModule.plotsWithNPV_Economic[LossSeverityModule.currentRiskGroup || 'Low'] || [];
    return sevItem;
  }

  get weightedNpvId() {
    const companyId = this.$store.getters.companyId;
    return `${companyId}_${this.severityList[this.severityRange[0]].LossSeverityAxisX}_${
      this.severityList[this.severityRange[1]].LossSeverityAxisX
    }`;
  }

  get weightedNpv() {
    return LossSeverityModule.weightedNpvMap.find(({ id }) => id === this.weightedNpvId)?.data;
  }

  getMidLabel() {
    if (!this.weightedNpv) return '';
    const value = this.weightedNpv[this.selectedRisk || this.currentRisk];
    return (
      (value && formatCurrencyV2(value.WeightedNPV, LossSeverityModule.currency, value.Unit)) || ''
    );
  }

  updateMidLabel() {
    if (!this.chart) return;
    const seriesMidLabel = this.chart.data.findSeriesById('severity-mid-label_series');
    if (seriesMidLabel) {
      seriesMidLabel.setPlotsIds(`${this.selectedRisk}_severity-mid-label`);
      seriesMidLabel.replaceSeriesData([this.rangeData.x, this.rangeData.x.map(() => 0)], true, [
        (this.weightedNpv && this.getMidLabel()) || '',
      ]);
    }
  }

  @Watch('weightedNpv')
  onWeightedNpvLoaded() {
    this.updateMidLabel();
  }
}
