var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container",style:(`height: ${_vm.height}px; 
    width: ${_vm.width}px;
    --border-size: ${_vm.thickness}px;
    --angl: ${_vm.angl}deg;
    --second-color: ${_vm.secondColor};
    --first-color: ${_vm.valuedColor};
    --bg-color: ${_vm.bgColor};
  `)},[_c('div',{staticClass:"shadow",style:(`
    --shadow-border: ${_vm.shadowSize}px;
    right: -${_vm.shadowSize}px;
    top: -${_vm.shadowSize}px;
  `)}),_c('div',{staticClass:"center",style:(`height: ${_vm.centerHeight}px; 
    width: ${_vm.centerWidth}px;
  `)}),_vm._l((_vm.valueRanges),function(tick,ind){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(ind !== _vm.valueRanges.length - 1),expression:"ind !== valueRanges.length - 1"}],key:ind,staticClass:"tick",style:(`
      transform: rotate(${_vm.countAngl(tick)}deg);
      background-color: ${_vm.bgColor};
    `)})}),_c('div',{staticClass:"arrow",style:(`transform: rotate(${_vm.angl}deg);
      width: ${_vm.length}px;
      --arrow-width: ${_vm.arrowWidth}px;
      right: calc(50% - ${_vm.arrowWidth / 2}px);
      bottom: -${_vm.arrowWidth / 2}px;
      transform-origin: calc(100% - ${_vm.arrowWidth / 2}px) 50%;
      `)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }